import {
  Box,
  Center,
  useColorModeValue,
  Heading,
  Text,
  Stack,
  Image,
  SimpleGrid,
} from '@chakra-ui/react';

const IMAGE =
  'https://images.freeimages.com/images/large-previews/50d/fishing-boats-1450441.jpg';
const placesData = [
  {
    image:
    'grand-mosque.jpg',
    title: 'Muscat',
  },
  {
    image:
    'salalah.jpg',
    title: 'Salalah',
  },
  {
    image:
      'road.jpg',
    title: 'Mutrah',
  },
];
function ProductSimple({ props }) {
  return (
    <Center py={12}>
      <Box
        role={'group'}
        p={6}
        maxW={'330px'}
        w={'full'}
        bg={useColorModeValue('white', 'gray.800')}
        boxShadow={'2xl'}
        rounded={'lg'}
        pos={'relative'}
        zIndex={1}
      >
        <Box
          rounded={'lg'}
          mt={-12}
          pos={'relative'}
          height={'230px'}
          _after={{
            transition: 'all .3s ease',
            content: '""',
            w: 'full',
            h: 'full',
            pos: 'absolute',
            top: 5,
            left: 0,
            backgroundImage: `url(${IMAGE})`,
            filter: 'blur(15px)',
            zIndex: -1,
          }}
          _groupHover={{
            _after: {
              filter: 'blur(20px)',
            },
          }}
        >
          <Image
            rounded={'lg'}
            height={230}
            width={282}
            objectFit={'cover'}
            src={props.image}
            alt="#"
          />
        </Box>
        <Stack pt={10} align={'center'}>
          <Text color={'gray.500'} fontSize={'sm'} textTransform={'uppercase'}>
            {/* Brand */}
          </Text>
          <Heading fontSize={'2xl'} fontFamily={'body'} fontWeight={500}>
            {props.title}
          </Heading>
          <Stack direction={'row'} align={'center'}>
            {/* <Text fontWeight={800} fontSize={'xl'}>
              $57
            </Text>
            <Text textDecoration={'line-through'} color={'gray.600'}>
              $199
            </Text> */}
          </Stack>
        </Stack>
      </Box>
    </Center>
  );
}

export default function Destinations() {
  return (
    <Box
      p={6}

      // l="20px"
    >
      <SimpleGrid gap={10} columns={{ base: 1, md: 3 }} spacing={10}>
        {placesData.map((card, index) => (
          <ProductSimple key={index} 
          props = {card}
          ></ProductSimple>
        ))}
        ,
      </SimpleGrid>
    </Box>
  );
}
