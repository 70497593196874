import { ReactElement } from 'react';
import {
  Box,
  SimpleGrid,
  Icon,
  Text,
  Stack,
  HStack,
  VStack,
  Flex,
  Image,
  Center,
} from '@chakra-ui/react';
import {
  FcAssistant,
  FcDonate,
  FcInTransit,
  FcLikePlaceholder,
  FcPhone
  
} from 'react-icons/fc';
import { FaAddressBook, FaAddressCard, FaCalendarMinus, FaClock, FaMailBulk, FaMailchimp, FaPhone, FaTimes, FaTimesCircle, FaVoicemail } from 'react-icons/fa';
const holidaySvg = <Image src="beach.svg" alt="SVG Icon" w={10} h={10} />;

const airTicketSvg = <Image src="flight.svg" alt="SVG Icon" w={10} h={10} />;
const tourSvg = <Image src="tour.svg" alt="SVG Icon" w={10} h={10} />;
const visaSvg = <Image src="visa.svg" alt="SVG Icon" w={10} h={10} />;
const umraSvg = <Image src="umrah.svg" alt="SVG Icon" w={10} h={10} />;
const hotelSvg = <Image src="hotel.svg" alt="SVG Icon" w={10} h={10} />;
// const phoneSvg = <Icon icon={FaPhone} alt="SVG Icon" w={10} h={10} />;

const Feature = ({ title, text, icon, description }) => {
  return (
    <VStack>
      <Flex
        w={20}
        h={20}
        align={'center'}
        justify={'center'}
        color={'black'}
        rounded={'full'}
        bg={'gray.100'}
        mb={1}
      >
        {icon}
      </Flex>
      <Text fontSize={{ base: '1rem', md: '1.3rem' }} fontWeight={700}>
        {title}{' '}
      </Text>
      <Text color={'gray.600'}>{text}</Text>
      <Text color={'gray.600'}>{description}</Text>
    </VStack>
  );
};

export default function ContactIconSection() {
  return (
    <Box
      p={10}

      // l="20px"
    >
      <SimpleGrid gap={10} columns={{ base: 2, md: 4 }} spacing={10}>
        <Feature
          icon={<FaPhone/>}
          title={'Phone'}
          description={'+968 95821793, +968 24420481'}
          // text={
          //   'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore...'
          // }
        />
        <Feature
          icon={<FaMailBulk/>}
          title={'Mail'}
          description={'sales@safetravelsoman.com'}
        />
        <Feature
         icon={<FaAddressCard/>}
          title={'Address'}
          description={'Seeb Souq, Opp. Bank Muscat'}
        />
        
        

        <Feature
           icon={<FaClock/>}
           title={'Office Hours'}
           description={'9AM - 10PM'}
        //    description={
        //     "Mon:	9:00 AM – 1:30 PM, 4:00 – 10:00 PM\nTue:	9:00 AM – 1:30 PM, 4:00 – 10:00 PM\nWed:	9:00 AM – 1:30 PM, 4:00 – 10:00 PM\nThu:	9:00 AM – 1:30 PM, 4:00 – 10:00 PM\nFri:	9:00 AM – 12:00 PM, 4:00 – 10:00 PM\nSat:	9:00 AM – 1:30 PM, 4:00 – 10:00 PM\nSun:	9:00 AM – 1:30 PM, 4:00 – 10:00 PM"
      //  }
          
        />
        

        <Box display={{ base: 'none', md: 'block' }}></Box>
      </SimpleGrid>
    </Box>
  );
}
