import { Text,Box, VStack,Center } from '@chakra-ui/react'
import React from 'react'

function OurServicesText() {
  return (
    <Box p="20px"  w="100%" textAlign="center">

     <VStack>
     <Text fontSize={{
        base: "1.4rem", md:"1.8rem"
      }}  
      fontWeight='semibold'
      
      >Our Services</Text>
     </VStack>
    <Center><Box  h="3.5px" bg="#E89E00" w="15%" ></Box></Center> 
   
    </Box>
  )
}

export default OurServicesText