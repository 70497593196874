import React from 'react';
import { Box, HStack, Text, Flex, Spacer,useColorModeValue } from '@chakra-ui/react';
import { FaLock, FaPassport, FaVoicemail } from 'react-icons/fa';
import { EmailIcon, PhoneIcon } from '@chakra-ui/icons';
export default function TopBanner() {
  return (
    <Box  w="100%" h="50px" bg=
    {useColorModeValue('gray.300', 'gray.900')} px="5%" display="flex" alignItems="center">
      <HStack spacing="10px" justify="space-between" width="100%">
        <EmailIcon color='grey'  fontWeight='semi-bold'></EmailIcon>
        <Text fontSize ={{base:"12px", md:"1.3rem"}} color='grey' fontWeight='semi-bold'>sales@safetravelsoman.com</Text>
        <Spacer></Spacer>
        <PhoneIcon color='grey'  fontWeight='semi-bold'></PhoneIcon>
        <Text fontSize ={{base:"12px", md:"1.3rem"}}  color='grey' whiteSpace="nowrap"  fontWeight='semi-bold'>+968 95821793</Text>
      </HStack>
    </Box>
  );
}
