import React from 'react'
import TopBanner from '../../components/TopBanner';
import { Box, Text } from '@chakra-ui/react';
import NavBarTwo from '../../components/NavBarTwo';
import ContactImage from './components/ContactImage';
import ContactIconSection from './components/ContactIcons';
import LocationMap from './components/LocationMap';
import LargeWithNewsletter from '../../components/Footer';
function Contact() {
  return (
    <Box>
       <TopBanner></TopBanner>
  <NavBarTwo></NavBarTwo>
  <ContactImage></ContactImage>
  <ContactIconSection></ContactIconSection>
  <LocationMap></LocationMap>
  <LargeWithNewsletter></LargeWithNewsletter>
    </Box>
  )
}

export default Contact