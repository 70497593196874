import React from 'react';
import TopBanner from '../../components/TopBanner';
import { Box, Text } from '@chakra-ui/react';
import NavBarTwo from '../../components/NavBarTwo';
import CaptionCarousel from '../../components/Carousel';
import WelcomeText from '../../components/WelcomeText';
import SimpleThreeColumns from '../../components/Services';
import OurServicesText from '../../components/OurServicesText';
import WhySafe from '../../components/WhySafe';
import TestimonialContent from '../../components/Testimonial';
import Destinations from '../../components/Destinations';
import LargeWithNewsletter from '../../components/Footer';



export default function HomePage() {
  return (
    <Box>
      <TopBanner></TopBanner>
  <NavBarTwo></NavBarTwo>
  <CaptionCarousel></CaptionCarousel>
  <WelcomeText></WelcomeText>
  <OurServicesText></OurServicesText>
  <SimpleThreeColumns></SimpleThreeColumns>
  {/* <WhySafe></WhySafe> */}
  <TestimonialContent></TestimonialContent>
  <Destinations></Destinations>
  <LargeWithNewsletter></LargeWithNewsletter>
    </Box>
  );
}
