import React from 'react';
import TopBanner from '../../components/TopBanner';
import { Box, Text, VStack, Center } from '@chakra-ui/react';
import NavBarTwo from '../../components/NavBarTwo';
import AboutImage from './components/AboutImage';
import LargeWithNewsletter from '../../components/Footer';
function About() {
  return (
    <Box>
      <TopBanner></TopBanner>
      <NavBarTwo></NavBarTwo>
      <AboutImage></AboutImage>
      <Box p="20px" w="100%" textAlign="center">
        <VStack>
          <Text
            fontSize={{
              base: '1.4rem',
              md: '1.8rem',
            }}
            fontWeight="semibold"
          >
            About Us
          </Text>
        </VStack>
        <Center>
          <Box h="3.5px" bg="#E89E00" w="15%"></Box>
        </Center>
        <Text p="1rem" fontWeight={'500'}>
          Safe Travel and Tourism LLC is one of the leading company offering the
          Travel and related services that include Domestic and International
          Air Ticketing, Corporate Travel, Oman Tours, International Holiday
          Packages, Umrah Visa and Visa Assistance Services. Head Quartered at
          Seeb, Muscat we have been providing reliable and safe travel
          experience to our customers for very long time. We, being one of the
          first choice of travelers to book their travel arrangements, always
          continues to strive to make travel easier and convenient for everyone
        </Text>
      </Box>
      <LargeWithNewsletter></LargeWithNewsletter>
    </Box>
  );
}

export default About;
