import { Box } from '@chakra-ui/react';

function LocationMap() {
  return (
    <Box>
      <iframe
        width="100%"
        height="400"
        frameborder="0"
        // scrolling="no"
        // marginHeight="0"
        // marginWidth="0"https://maps.app.goo.gl/uvbLXGguK9APE4keA
        title="Location Map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3653.8493216812685!2d58.18423467396752!3d23.681345891426467!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e8de5320361bff1%3A0x41c3d67bd109c52f!2zU0FGRSBUUkFWRUwgJiBUT1VSUyDYp9mE2KLZhdmG2Kkg2YTZhNiz2YHYsSDZiNin2YTYs9mK2KfYrdip!5e0!3m2!1sen!2sin!4v1696660530584!5m2!1sen!2sin"

        // src="https://maps.app.goo.gl/Ns4ARm4F79PZroYP8"
      ></iframe>
    </Box>
  );
}

export default LocationMap;
