import { Box, Image } from '@chakra-ui/react';

function ContactImage() {
  return (
    <Box position="relative">
      {/* Image */}
      <Image
        h="70vh"
        w="100vw"
        fit="cover"
        src="contact-us.jpg"
        alt="Contact Us"
      />

      {/* Text overlay */}
      <Box
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        textAlign="center"
        color="white" // Set the text color
        fontWeight="bold"
        fontSize="2rem" // Set the font size
      >
        Contact Us
       
      </Box>
    </Box>
  );
}

export default ContactImage;
