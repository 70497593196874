// import React from 'react';
import React, { useState } from 'react';

import {
  Box,
  Flex,
  Spacer,
  Text,
  Link,
  Image,
  IconButton,
  useDisclosure,
  Stack,
} from '@chakra-ui/react';
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';
import logo from './without_bg.png'; // Adjust the path as needed

// import {
//   BrowserRouter as Router,
//   Switch,
//   Route,
//   Link as RouteLink,
// } from 'react-router-dom';


function NavBarTwo() {
  const { isOpen, onToggle } = useDisclosure();
  const currentPath = window.location.pathname;
  const [activeTab, setActiveTab] = useState('');

  const routes = [
    { path: '/', name: 'Home' },
    { path: '/about', name: 'About' },
    { path: '/contact', name: 'Contact' },
  ];
  return (
    <Flex
      as="nav"
      align="center"
      justify="space-between"
      //padding="1rem"
      bg="white" // Set your desired background color
      color="white" // Set your desired text color
      wrap="wrap"
    >
      <Flex align="center" mr={5}>
        {/* Logo on the left */}
        <Image
          src={logo}
          alt="Logo"
          width={{ base: '180px', md: '130px' }}
          height={{ base: '110px', md: 'auto' }}
        />
      </Flex>

      {/* Menu icon for smaller screens */}
      <Box w="40px"  display={{ base: 'block', md: 'none' }} onClick={onToggle}>
        {isOpen ? <CloseIcon   color="black" /> : <HamburgerIcon  fontSize = "1.5rem" color="black" />}
      </Box>

      {/* Navigation links */}
      <Box
        mr={{ base: '0px', md: '10rem' }}
        display={{ base: isOpen ? 'block' : 'none', md: 'block' }}
        width={{ base: 'full', md: 'auto' }}
      >
        <Stack
          spacing={4}
          align={['center', 'center', 'center', 'flex-end']}
          direction={['column', 'column', 'column', 'row']}
          pt={[4, 4, 4, 0]}
        >
       

          {routes.map((route) => (
            <Link
            
              key={route.path}
               href={route.path}
             // href='/contact'
              fontWeight={currentPath === route.path ? 'bold' : 'bold'}
              color="black"
              fontSize={['1rem', '1rem', '1.2rem', '1.2rem']}
              _hover={{ color: '#E89E00' }}
              onClick={() => setActiveTab(route.path)} // Set the active tab
              position="relative" // Required for the indicator line
            >
              {route.name}
              {currentPath === route.path && (
                <Box
                  w="100%"
                  h="4px"
                  bg="#E89E00"
                  position="absolute"
                  bottom="-5px" // Position the indicator line below the link
                  
                ></Box>
              )}
            </Link>
          ))}
        </Stack>
      </Box>

      {/* Spacer to push links to the right */}
    </Flex>
  );
}

export default NavBarTwo;
