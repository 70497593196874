import React from 'react';
import { ChakraProvider, theme } from '@chakra-ui/react';
import { StrictMode } from 'react';

import HomePage from './pages/home/HomePage';
import About from './pages/about/About';
// 1. Import `extendTheme`
import { extendTheme } from '@chakra-ui/react';
// import { Router } from 'react-router-dom';

// import {
//   BrowserRouter as Router,
//   Switch,
//   Route,
//   Link as RouteLink
// } from "react-router-dom";

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Contact from './pages/contact/Contact';

function App() {
  const theme = extendTheme({
    fonts: {
      body: 'Poppins, sans-serif', // Font for the body of your application
      heading: 'Raleway, sans-serif', // Font for headings
      // You can define more font families for different parts of your application here.
    },
    colors: {
      primary: {
        main: '#7bb9e8',
        50: '#e3f2fd',
        100: '#bbdefb',
        200: '#90caf9',
        300: '#64b5f6',
        400: '#42a5f5',
        500: '#2196f3',
        600: '#1e88e5',
        700: '#1976d2',
        800: '#1565c0',
        900: '#0d47a1',
      },
    },
  });

  const data = Array.from({ length: 100 }, (_, index) => `Item ${index + 1}`);

  return (
    <StrictMode>
      <ChakraProvider theme={theme}>
        <Router>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>

          {/* <HomePage></HomePage> */}
        </Router>
      </ChakraProvider>
    </StrictMode>
  );
}

export default App;
