import { Box, Center, Text, VStack } from '@chakra-ui/react';
import React from 'react';

function WelcomeText() {
  return (
    <Box p="20px"  w="100%" textAlign="center">

     <VStack>
     <Text fontSize={{
        base: "1.4rem", md:"1.8rem"
      }}  
      fontWeight='semibold'
      
      >Welcome to Safe Travel and Tours</Text>
     </VStack>
    <Center><Box  h="3.5px" bg="#E89E00" w="20%" ></Box></Center> 
    <Text p="1rem" fontWeight={'500'} >Safe Travel and Tourism LLC is one of the leading company offering the Travel and related services that include Domestic and International Air Ticketing, Corporate Travel, Oman Tours, International Holiday Packages, Umrah Visa and Visa Assistance Services.</Text>
    </Box>
  );
}

export default WelcomeText;
